<template>
  <v-menu
    ref="vmenu"
    v-model="menu"
    origin="center center"
    :disabled="item.id != duplicate_id"
    transition="slide-y-transition"
    :close-on-content-click="false"
    content-class="my-menu elevation-0"
    max-width="300"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        {{ item.title ? item.title : "Untitled" }}
      </div>
    </template>
    <v-card outlined class="custom-border">
      <v-card-text>
        <h3 class="primary--text poppins">Move to :</h3>
        <v-divider class="my-2" />
        <v-list dense>
          <v-list-item-group v-model="selected" active-class="active">
            <v-list-item
              v-for="(course, i) in courses"
              :key="i"
              :value="course.id"
              :disabled="item.course_id == course.id"
            >
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <v-list-item-title class="roboto f14" v-text="course.title" />
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon size="20" color="primary">{{
                    active ? "mdi-check" : ""
                  }}</v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-btn :loading="loading" color="primary" block @click="move">Done</v-btn>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: ["menu", "item", "duplicate_id", "courses"],
  data: () => ({
    selected: "",
    loading: false,
  }),
  computed: {},
  methods: {
    ...mapActions("instructor", ["moveAssessmentAction"]),
    ...mapMutations(["alertMutation"]),

    move() {
      this.loading = true;
      this.moveAssessmentAction({
        assessment_id: this.item.id,
        course_id: this.selected,
      }).then(() => {
        this.loading = false;
        this.$emit("hideMenu");
        this.alertMutation({ show: true, text: "Assessment Successfully Moved!", type: "success" });
      });
    },
  },
};
</script>

<style scoped>
.active {
  background-color: #d9dff6 !important;
  border-radius: 5px !important;
}
.my-menu {
  /* margin-top: 40px; */
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 10px;
  /* right: 10px; */
  transform: translateX(-100%);
  /* box-shadow: #fff; */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04) !important; */
  /* box-shadow: 0 -5px 5px -5px #333; */
  width: 13px;
  height: 13px;
  border-top: 12px solid transparent;
  border-left: 12px solid transparent;
  border-right: 13px solid #e5e5e5;
  border-bottom: 12px solid transparent;
}
</style>
