<template>
  <section style="background-color: transparent">
    <LinearProgress v-if="loading" />
    <v-expansion-panels 
      accordion
      focusable
      color="transparent"
      >
      <v-expansion-panel
        v-for="(item,i) in courses"
        :key="i"
        class="px-3"
        v-if="(5 * page) - 5 <=  i && i < (5 * page)"
      >
        <v-expansion-panel-header class="poppins fw500 f14">{{ item.title }}</v-expansion-panel-header>
        <v-expansion-panel-content dense>
          <AssessmentCourseTable 
            :items="assessments.filter(i => i.course_id == item.id)" 
            :itemLength="assessments.filter(i => i.course_id == item.id).length"
            @preview="preview"
            @move="move"
            @duplicate="duplicate"
            @remove="remove"
            @share="share"
             />
          <!-- <AssessmentCourseTable 
            :items="assessments.filter(i => i.course_id == item.id)" 
            :itemLength="item.assessment_questions.length"
            @preview="preview"
            @move="move"
            @duplicate="duplicate"
            @remove="remove"
            @share="share"
             /> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row align="center" class="ma-2" v-if="courses.length > 0 && !loading" style="background-color: transparent">
        <v-col lg="12">
            <v-pagination
            v-model="page"
            :length="parseInt((coursesLength/5) + (coursesLength%5 > 0 ? 1 : 0))"
            ></v-pagination>
        </v-col>
    </v-row>
    <v-container
        v-if="courses.length === 0 && !loading"
        color="transparent"
        class="d-flex flex-column align-center justify-center my-10"
      >
        <div class="">
          <v-img 
            :max-width="$vuetify.breakpoint.mobile?200:300"
            :src="require('../../../assets/default/empty_drafts.png')" />
        </div>
        <h3 class="poppins secondary-1--text fw600 my-5">Nothing in Assessments</h3>
    </v-container>
    <ShareDialog :data="toShare" :dialog="dialog" @close="dialog = false" />
    <MoveDialog
      action="mine"
      :data="toShare"
      :courses="courses"
      :dialog="moveDialog"
      @close="moveDialog = false"
    />
    <PreviewDialog
      :data="toShare"
      :dialog="previewDialog"
      @close="previewDialog = false"
    />
    <delete-dialog
      :dialog="deleteDialog"
      :id="itemToRemove.id"
      :course_id="itemToRemove.course_id"
      type="Assessment"
      store="instructor/deleteAssessmentAction"
      @close="deleteDialog = false"
    />
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import ShareDialog from "./AssessmentShareDialog.vue";
import DuplicateTooltip from "./DuplicateTooltip.vue";
import MoveDialog from "./AssessmentMoveDialog.vue";
import PreviewDialog from "./AssessmentPreviewDialog.vue";
import AssessmentCourseTable from "./AssessmentCourseTable.vue"
export default {
  props: ["assessments", "loading"],
  components: {
    AssessmentCourseTable,
    ShareDialog,
    DuplicateTooltip,
    MoveDialog,
    PreviewDialog,
  },
  data: () => ({
    tab: null,
    dialog: false,
    moveDialog: false,
    deleteDialog: false,
    previewDialog: false,
    toShare: {
      course: {
        title: 'Untitled'
      }
    },
    menu: false,
    items: ["My Assessments", "Assessment Bank"],
    itemToRemove: {
      id: 0,
      course_id: null
    },
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
  }),
  computed: {
    ...mapState("instructor", {
      duplicate_id: (state) => state.duplicate_id,
      courses: (state) => state.courses,
      coursesLength: (state) => state.courses.length
    }),
  },
  created() {
    window.onbeforeunload = () => {
      alert("This page cannot be reload");
    };

  },
  methods: {
    ...mapActions("instructor", ["duplicateAssessmentAction", "getCoursesAction", "getInstructorAssessmentsAction"]),
    ...mapMutations(["alertMutation"]),

    share(data) {
      this.dialog = true;
      this.toShare = data;
    },
    remove(data) {
      this.deleteDialog = true;
      this.itemToRemove = data;
    },
    duplicate(item) {
      this.duplicateAssessmentAction({
        course_id: item.course_id,
        assessment_id: item.id,
      }).then(() => {
        this.getCoursesAction().then(()=>{
          this.getInstructorAssessmentsAction();
        });
        setTimeout(() => {
          this.alertMutation({ show: true, text: "Assessment Successfully Duplicated!", type: "success" });
          this.menu = true;
        }, 1500);
        // this.$refs.vmenu.open()
      });
    },
    move(data) {
      this.getCoursesAction().then(() => {
        this.moveDialog = true;
      });
      this.toShare = data;
    },

    preview(data) {
      this.previewDialog = true;
      this.toShare = data;
    },
  },
  watch: {
    //get all assessment when triggerd an action
    deleteDialog(val) {
      return !val ? this.$emit("duplicate") : "";
    },

    menu(val) {
      return !val ? this.$emit("duplicate") : "";
    },

    moveDialog(val) {
      return !val ? this.$emit("duplicate") : "";
    },
  },
};
</script>
