<template>
  <section>
    <LinearProgress v-if="loading" />
    <v-data-table
      v-if="assessments.length !== 0"
      :headers="shared_with_me_tbl"
      :items="assessments"
      :items-per-page="5"
      class="elevation-1 poppins"
    >
      <template v-slot:item.title="{ item }">
        <v-badge
          dot
          left
          inline
          v-if="new_assessment_uuid && item.assessment.uuid === new_assessment_uuid"
          class="pl-1"
        > {{ item.assessment.title ? item.assessment.title : "Untitled"}}
        </v-badge>
        <span v-else> {{ item.title ? item.title : "Untitled"}} </span>
      </template>
      <template v-slot:item.shared_by="{ item }">
        <span v-if="item">{{ item.shared_by_user.email ? item.shared_by_user.email : '' }}</span>
      </template>
      <template v-slot:item.date_shared="{ item }">
        {{ $dateFormat.mmDDyy(item.created_at) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon color="primary" @click="move(item.assessment)">
          <v-icon>mdi-folder-move-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    
    <v-container
        v-if="assessments.length === 0 && !loading"
        color="transparent"
        class="d-flex flex-column align-center justify-center my-10"
      >
        <div class="">
          <v-img 
            :max-width="$vuetify.breakpoint.mobile?200:300" 
            :src="require('../../../assets/default/empty_drafts.png')"/>
        </div>
        <h3 class="poppins secondary-1--text fw600 my-5 text-center">Nothing in Shared</h3>
    </v-container>

    <MoveDialog
      action="shared"
      :data="toShare"
      :courses="courses"
      :dialog="moveDialog"
      @close="moveDialog = false"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MoveDialog from "./AssessmentMoveDialog.vue";
import { shared_with_me_tbl } from '../../../constants/tblheaders/shared'

export default {
  props: ["assessments", "new_assessment_uuid", "loading"],
  components: {
    MoveDialog,
  },
  data: () => ({
    moveDialog: false,
    toShare: {
      title: 'Untitled'
    },
    shared_with_me_tbl,
  }),
  computed: {
    ...mapState("instructor", {
      courses: (state) => state.courses,
    }),
  },
  created() {},
  methods: {
    ...mapActions("instructor", ["getCoursesAction"]),

    move(data) {
      this.getCoursesAction().then(() => {
        this.moveDialog = true;
      });
      this.toShare = data;
    },
  },
  watch: {},
};
</script>

<style scoped>
.my-menu {
  /* margin-top: 40px; */
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 10px;
  /* right: 10px; */
  transform: translateX(-100%);
  /* box-shadow: #fff; */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04) !important; */
  /* box-shadow: 0 -5px 5px -5px #333; */
  width: 13px;
  height: 13px;
  border-top: 12px solid transparent;
  border-left: 12px solid transparent;
  border-right: 13px solid #e5e5e5;
  border-bottom: 12px solid transparent;
}

</style>