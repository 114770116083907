import { render, staticRenderFns } from "./AssessmentShareToOtherTable.vue?vue&type=template&id=323f6d30&scoped=true"
import script from "./AssessmentShareToOtherTable.vue?vue&type=script&lang=js"
export * from "./AssessmentShareToOtherTable.vue?vue&type=script&lang=js"
import style0 from "./AssessmentShareToOtherTable.vue?vue&type=style&index=0&id=323f6d30&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323f6d30",
  null
  
)

export default component.exports